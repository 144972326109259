import React, { ReactNode } from 'react';
import { DefaultTheme } from 'styled-components';
import { Element } from './PaginateContainer.styles';

export interface PaginateContainerProps {
  readonly color?: keyof DefaultTheme['color'] & string;
  className?: string;
  children?: ReactNode;
}

const PaginateContainer = ({ children, ...props }: PaginateContainerProps) => {
  return <Element {...props}>{children}</Element>;
};

export default PaginateContainer;
