import { useRef } from 'react';

const useImagePulse = () => {
  const className = 'img-pulsing';
  const containerRef = useRef<HTMLDivElement & HTMLAnchorElement>(null);

  const handleOnCompleted = () => {
    if (containerRef.current) {
      containerRef.current.classList.remove(className);
    }
  };

  return {
    className,
    containerRef,
    handleOnCompleted,
  };
};

export default useImagePulse;
