export const navigationOption = (mobile?: boolean) => {
  return {
    enabled: !mobile,
    nextEl: `.carrousel-next`,
    prevEl: `.carrousel-prev`,
  };
};

export const paginationOption = {
  clickable: true,
  el: `.paginate-container`,
  bulletClass: 'flat-bullet',
  bulletActiveClass: 'flat-bullet-active',
  clickableClass: 'paginate-clickable',
  horizontalClass: 'paginate-horizontal',
  renderBullet: (index: number, className: string) => '<span role="button" class="' + className + '"></span>',
  dynamicBullets: false,
};
