import styled, { css } from 'styled-components';
import { imageBlurredFadeIn } from '@/components/image/imageLoadedFadeIn.styles';
import { imagePulse } from '@/components/image/imagePulse.styles';

export const Element = styled.div.withConfig({ shouldForwardProp: (prop) => !['radius'].includes(prop) })<{
  radius: boolean;
}>`
  ${({ theme, radius, ...props }) => {
    return css`
      position: relative;
      text-align: center;

      ${radius &&
      css`
        border-radius: ${theme.radius.sm};
      `};
      overflow: hidden;

      &:hover {
        box-shadow: ${theme.box_shadow.base};
      }

      // Apply the effect on hover
      > a {
        &:hover {
          figcaption {
            &::before,
            &::after {
              border-radius: ${theme.radius.sm};
              opacity: 1;
              transform: scale(1);
            }
          }
          h2,
          h3,
          p {
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
          img {
            opacity: 0.4;
          }
        }
      }
    `;
  }};
`;

export const Figure = styled.figure<{
  minHeight: number | string;
  minWidth: string | number;
  minHeightMobile?: string | number;
}>`
  ${({ theme, minWidth, minHeight, minHeightMobile, ...props }) => {
    return css`
      position: relative;
      // TODO: FIX THE LAYOUT SHIFT BY ADDING OR HANDLE THE WITH PROPERLY
      //max-width: ${minWidth}px;
      min-height: ${minHeight}px;

      ${minHeightMobile &&
      css`
        @media screen and (max-width: ${theme.breakpoint.sm}) {
          min-height: ${minHeightMobile}px;
        }
      `}

      background: ${theme.color.primary_500};
      text-align: center;
      overflow: hidden;
      margin: 0;

      > img {
        object-fit: cover;
        // DISABLE BY imageBlurredFadeIn EFFECT
        //opacity: 0.8;
        //transition: opacity 0.35s;
        ${imageBlurredFadeIn};
      }

      &:hover img {
        opacity: 0.4 !important;
      }

      // Pulse Effect
      ${imagePulse};
    `;
  }};
`;

export const FigureCaption = styled.figcaption`
  ${({ theme, ...props }) => {
    return css`
      --border-width: 1px;
      --space-around: 1.5rem;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      color: ${theme.color.dark};
      text-transform: uppercase;
      backface-visibility: hidden;

      &::before,
      &::after {
        position: absolute;
        top: var(--space-around);
        right: var(--space-around);
        bottom: var(--space-around);
        left: var(--space-around);
        content: '';
        opacity: 0;
        border-radius: 0;
        transition: all 1s ease, opacity 0.35s, transform 0.35s;
      }

      &::before {
        border-top: var(--border-width) solid ${theme.color.white};
        border-bottom: var(--border-width) solid ${theme.color.white};
        transform: scale(0, 1);
      }
      &::after {
        border-right: var(--border-width) solid ${theme.color.white};
        border-left: var(--border-width) solid ${theme.color.white};
        transform: scale(1, 0);
      }

      h2,
      h3 {
        padding-top: 4rem;
        // TODO: CREATE A NEW TOKEN breakpoint_max or xxl2
        //@media screen and (min-width: {theme.breakpoint.xxl2}) {
        @media screen and (min-width: 1800px) {
          padding-top: 2.5rem;
        }
        transition: transform 0.35s;
        transform: translate3d(0, -1.25rem, 0);
        color: ${theme.color.white};
      }
      p {
        padding: 1rem 2rem;
        opacity: 0;
        transition: opacity 0.35s, transform 0.35s;
        transform: translate3d(0, 1.25rem, 0);
      }
    `;
  }};
`;
