import { css, keyframes } from 'styled-components';

export const pulse = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: .2;
  }
  100% {
    opacity: 0;
  }
`;

// Applies the pulse effect in the container image wrapper
export const imagePulse = css`
  // Pulse
  &.img-pulsing {
    &:before {
      content: '';
      position: absolute;
      inset: 0;
      background: white;
      animation: ${pulse} 1s infinite;
    }
  }
`;
