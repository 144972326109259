import styled, { css } from 'styled-components';

export const GridContainer = styled.div`
  ${({ theme, ...props }) => {
    return css`
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: ${theme.spacing.base};

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        grid-template-rows: repeat(2, 1fr);
        gap: ${theme.spacing.md};
      }
    `;
  }};
`;

export const CarrouselWrapper = styled.div`
  ${({ theme, ...props }) => {
    return css`
      .swiper {
        // This will make the Swiper container NOT cause a Layout Shift
        &:not(.swiper-initialized) .swiper-slide {
          visibility: hidden;
          .swiper-wrapper {
            //
          }
        }
      }
      .swiper-slide {
        //
      }
    `;
  }};
`;
