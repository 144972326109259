import React from 'react';
import { Element, Figure, FigureCaption } from './ImageFeatured.styles';
import { Title } from '@/components/typography';
import Text from '@/components/typography/Text/Text';
import Link from '@/providers/Link';
import Image from '@/providers/Image';
import { TitleProps } from '@/components/typography/Title/Title';
import useImagePulse from '@/components/image/imagePulseHook';

export interface ImageFeaturedProps {
  href: string;
  title: string;
  description?: string;
  radius?: boolean;
  image: ImageObjType;
  minWidth?: number | string;
  minHeight?: number | string;
  minHeightMobile?: number | string;
  titleProps?: TitleProps;
}

const ImageFeatured = ({
  href,
  title,
  description,
  image,
  radius = true,
  titleProps,
  minWidth = 'auto',
  minHeight = 168,
  minHeightMobile,
  ...props
}: ImageFeaturedProps) => {
  const { className, containerRef, handleOnCompleted } = useImagePulse();
  if (!image) return null;
  return (
    <Element radius={radius}>
      <Link href={href}>
        <Figure
          minWidth={minWidth}
          minHeight={minHeight}
          minHeightMobile={minHeightMobile}
          className={className}
          ref={containerRef}
        >
          <Image
            src={image.imageUrl}
            alt={image.title}
            fill
            priority={false}
            placeholder={`data:image/jpeg;base64,${image.blurDataUrl}`}
            fadeInOnBlur
            handleOnCompleted={handleOnCompleted}
          />
          <FigureCaption>
            <Title as="h3" size="lg" {...titleProps}>
              {title}
            </Title>
            {description && (
              <Text color="white" size="sm">
                {description}
              </Text>
            )}
          </FigureCaption>
        </Figure>
      </Link>
    </Element>
  );
};

export default ImageFeatured;
