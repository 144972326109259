import styled, { css } from 'styled-components';
import { PaginateContainerProps } from './PaginateContainer';
import { ButtonFlatBulletProps } from '@/components/button/ButtonFlatBullet/ButtonFlatBullet';
import { baseFlatBulletCss } from '@/components/button/ButtonFlatBullet/ButtonFlatBullet.styles';

export const Element = styled.div<PaginateContainerProps & ButtonFlatBulletProps>`
  ${({ theme, ...props }) => {
    return css`
      min-height: ${theme.spacing.sm};
      margin-top: ${theme.spacing.md};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${theme.spacing.base};

      span[role='button'],
      .flat-bullet {
        ${baseFlatBulletCss};
      }

      span.flat-bullet-active,
      span[aria-current='true'] {
        background: ${theme.color.secondary_200};
      }

      .paginate-horizontal,
      .paginate-clickable {
        background: transparent;
      }
    `;
  }};
`;
