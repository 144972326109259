import React, { ReactNode, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Pagination } from 'swiper';
import { isMobile } from '@/helpers/browser';
import ImageFeatured from '@/components/image/ImageFeatured/ImageFeatured';
import PaginateContainer from '@/components/carrousel/PaginateContainer/PaginateContainer';
import {
  CarrouselWrapper,
  GridContainer,
} from '@/features/home/components/CarrouselFeaturedPackage/CarrouselFeaturedPackage.styles';
import { paginationOption } from '@/lib/swiperConfig';

export interface CarrouselFeaturedPackageOffersProps {
  data: [FeaturedOfferType];
  children?: ReactNode;
}

const CarrouselFeaturedPackage = ({ data, children, ...props }: CarrouselFeaturedPackageOffersProps) => {
  const mobile = isMobile();

  let dataGrouped = useMemo(() => {
    let groupedBy2Rows = [];
    for (let i = 0; i < data.length; i++) {
      let item: FeaturedOfferType = data[i];
      let item2: FeaturedOfferType = data[i + 1];
      if (i % 2 == 0) {
        groupedBy2Rows.push([{ ...item }, item2 ? { ...item2 } : false].filter(Boolean));
      }
    }
    return groupedBy2Rows;
  }, [data]);

  return (
    <CarrouselWrapper>
      <Swiper
        // spaceBetween={mobile ? '16px' : '32px'}
        // slidesPerView={mobile ? 1 : 3}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 32,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 32,
          },
        }}
        pagination={paginationOption}
        modules={[A11y, Pagination]}
      >
        {dataGrouped.map((group, index) => (
          <SwiperSlide key={index}>
            <GridContainer>
              {group.map((featured) => (
                <ImageFeatured
                  key={featured.id}
                  title={featured.destino.title}
                  description={featured.title}
                  href={featured.permalink}
                  image={featured.image as ImageObjType}
                  minHeight={304}
                  minHeightMobile={168}
                />
              ))}
            </GridContainer>
          </SwiperSlide>
        ))}
        <PaginateContainer className="paginate-container" />
      </Swiper>
    </CarrouselWrapper>
  );
};

export default CarrouselFeaturedPackage;
